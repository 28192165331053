<template>
  <div class="appointmentList-view">
    <el-form
      :model="formData"
      :rules="formRules"
      ref="formData"
      :inline="true"
      label-width="110px"
    >
      <p class="fs8 fwbold mb10">活动基础设置</p>
      <el-row>
        <el-form-item label="活动名称" prop="name">
          <el-input
            type="text"
            placeholder="请输入活动名称"
            v-model="formData.name"
            style="width: 400px"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="活动概述" prop="description">
          <el-input
            type="textarea"
            placeholder="请输入活动概述"
            maxlength="150"
            v-model="formData.description"
            style="width: 400px"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item label="活动费用">
          <el-input
            type="number"
            class="suffixInput"
            placeholder="请输入活动费用"
            v-model="formData.activityFee"
            style="width: 135px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="推广奖励金额">
          <el-input
            type="number"
            class="suffixInput"
            :disabled="!formData.activityFee"
            placeholder="请输入奖励金额"
            v-model="formData.shareFee"
            style="width: 135px"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="主办单位" prop="hostUnit">
          <el-input
            type="text"
            placeholder="请输入单位名称"
            v-model="formData.hostUnit"
            style="width: 400px"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="联系方式" prop="callPhone">
          <el-input
            type="number"
            placeholder="请输入联系方式"
            v-model="formData.callPhone"
            style="width: 400px"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="活动标签" prop="activityLabel">
          <el-select
            v-model="formData.activityLabel"
            multiple
            placeholder="请选择"
            class="w400"
            :multiple-limit="3"
          >
            <el-option
              v-for="(row, index) in tagData"
              :key="index"
              :label="row.name"
              :value="row.labelId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="报名时间" prop="signEndTime">
          <el-date-picker
            v-model="signTime"
            type="datetimerange"
            :unlink-panels="true"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            @change="changeApplyTime"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="活动日期" :unlink-panels="true" prop="endTime">
          <el-date-picker
            v-model="pickerValue"
            :unlink-panels="true"
            align="right"
            :disabled="formData.signEndTime ? false : true"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            @change="changePickerValue"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item label="活动类型">
          <el-radio-group v-model="formData.activityType">
            <el-radio-button :label="0">线下</el-radio-button>
            <el-radio-button :label="1">线上</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-row>

      <div v-if="formData.activityType == 0">
        <el-row>
          <el-form-item label="活动地点" prop="activityPlace">
            <el-input
              type="text"
              class="mr10"
              placeholder="请输入活动地点"
              v-model="formData.activityPlace"
              style="width: 400px"
            >
            </el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="坐标" required>
            <div class="d-flex">
              <el-form-item prop="lng">
                <el-input
                  class="w130 mr10"
                  type="number"
                  placeholder="地理经度"
                  v-model="formData.lng"
                  clearable
                />
              </el-form-item>
              <el-form-item prop="lat">
                <el-input
                  class="w130 mr10"
                  type="number"
                  placeholder="地理纬度"
                  v-model="formData.lat"
                  clearable
                />
              </el-form-item>
              <el-button
                type="primary"
                style="height: 32px"
                icon="el-icon-location-outline"
                @click="showMapFun"
              ></el-button>
            </div>
          </el-form-item>
        </el-row>
      </div>
      <el-row>
        <el-form-item label="报名人数限制" prop="userNum">
          <div class="d-flex align-items-center">
            <el-input
              class="w100 mr10"
              type="number"
              v-model="formData.userNum"
              @change="
                () => {
                  formData.userNum =
                    formData.userNum < 0 ? '' : formData.userNum;
                }
              "
            >
            </el-input>
          </div>
        </el-form-item>
      </el-row>
      <p class="fs8 fwbold mb10">活动基础设置</p>
      <el-row>
        <el-form-item label="封面图片" prop="photos">
          <div class="d-flex align-items-center">
            <el-upload
              :action="uploadUrl"
              class="carouselUpload"
              list-type="picture-card"
              :class="{ disabled: uploadDisabled }"
              :on-remove="handleLogoRemove"
              :on-success="handleSuccess"
              :file-list="fileList"
              :limit="1"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">
                上传图的最佳尺寸750px像素*750px像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-row>
      <p class="fs8 fwbold mb10"><span class="cred">*</span> 活动详情内容</p>
      <el-form-item prop="details">
        <!-- //富文本 -->
        <quill-editor
          style="height: 100%; width: 910px; margin-left: 110px"
          v-model="formData.details"
          ref="myQuillEditor"
          :options="editorOption"
        />
      </el-form-item>

      <el-row>
        <el-form-item label="" prop="ruleIn">
          <p class="fs8 fwbold mb10">
            参与该活动的才能报名<el-button
              type="danger"
              title="清空"
              @click="delcheckedActivity('ruleIn', 'all')"
              icon="el-icon-delete"
              style="float: right"
              class="ml10"
            ></el-button
            ><el-button
              type="primary"
              style="float: right"
              @click="showActivityFun('ruleIn')"
              >选择活动</el-button
            >
          </p>
          <commonTable
            style="margin-left: 110px; width: 910px"
            :tableData="confirmCheckedRuleInActivity"
            :paginationVisible="false"
          >
            <template v-slot:table>
              <el-table-column
                prop="aeId"
                width="50"
                align="center"
                label="序号"
              >
              </el-table-column>
              <el-table-column
                prop="photos"
                width="130"
                align="center"
                label="活动图片"
              >
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.photos"
                    style="width: 100px; height: 80px; margin-bottom: -10px"
                    :src="scope.row.photos"
                  ></el-image>
                  <p
                    v-else
                    style="width: 100px; height: 80px; line-height: 80px"
                  >
                    暂无图片
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                width="200"
                label="活动名称"
                show-overflow-tooltip
              />
              <el-table-column
                prop="description"
                width="250"
                show-overflow-tooltip
                align="center"
                label="活动概述"
              />
              <el-table-column
                prop="startTime"
                width="250"
                show-overflow-tooltip
                align="center"
                label="活动时间周期"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime + " ~ " + scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                prop="visitTime"
                width="80"
                align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    slot="reference"
                    style="color: #fd634e"
                    type="text"
                    @click="delcheckedActivity('ruleIn', 'row', scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </template>
          </commonTable>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="" prop="ruleOut">
          <p class="fs8 fwbold mb10">
            没参与该活动的才能报名<el-button
              type="danger"
              title="清空"
              @click="delcheckedActivity('ruleOut', 'all')"
              icon="el-icon-delete"
              style="float: right"
              class="ml10"
            ></el-button
            ><el-button
              type="primary"
              style="float: right"
              @click="showActivityFun('ruleOut')"
              >选择活动</el-button
            >
          </p>
          <commonTable
            style="margin-left: 110px; width: 910px"
            :tableData="confirmCheckedruleOutActivity"
            :paginationVisible="false"
          >
            <template v-slot:table>
              <el-table-column
                prop="aeId"
                width="50"
                align="center"
                label="序号"
              >
              </el-table-column>
              <el-table-column
                prop="photos"
                width="130"
                align="center"
                label="活动图片"
              >
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.photos"
                    style="width: 100px; height: 80px; margin-bottom: -10px"
                    :src="scope.row.photos"
                  ></el-image>
                  <p
                    v-else
                    style="width: 100px; height: 80px; line-height: 80px"
                  >
                    暂无图片
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                width="200"
                label="活动名称"
                show-overflow-tooltip
              />
              <el-table-column
                prop="description"
                width="250"
                show-overflow-tooltip
                align="center"
                label="活动概述"
              />
              <el-table-column
                prop="startTime"
                width="250"
                show-overflow-tooltip
                align="center"
                label="活动时间周期"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime + " ~ " + scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                prop="visitTime"
                width="80"
                align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    slot="reference"
                    style="color: #fd634e"
                    type="text"
                    @click="delcheckedActivity('ruleOut', 'row', scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </template>
          </commonTable>
        </el-form-item>
      </el-row>
      <activity-form
        ref="activityForm"
        :initFormData="form.detailRequests"
      ></activity-form>

      <el-row style="margin-top: 10px">
        <el-form-item label="上下架设置">
          <el-switch
            v-model="formData.isPutOn"
            active-text="上架"
            inactive-text="下架"
          >
          </el-switch>
        </el-form-item>
      </el-row>

      <el-row align="right" style="width: 800px; text-align: right">
        <el-button
          style="background-color: #51cdcb; color: white"
          @click="saveForm"
          >发布</el-button
        >
      </el-row>
    </el-form>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="选择活动"
      :visible.sync="showActivity"
      :destroy-on-close="true"
      width="80%"
      center
    >
      <commonTable
        :tableData="activityTableData"
        :loading="activityLoading"
        @handleSizeChange="activityHandleSizeChange"
        @handleCurrentChange="activityHandleCurrentChange"
        :currentPage="activityCurrentPage"
        :total="activityTotal"
      >
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <!-- <el-radio class="ml10" v-model="activityCheckedData.id" :label="scope.row.aeId"
                                @change="selectActivity(scope.row)">
                                {{ ' ' }}
                            </el-radio> -->
              <el-checkbox
                v-model="aeIds[scope.$index]"
                @change="selectActivity($event, scope.row)"
                :label="scope.row.aeId"
                >{{ "" }}</el-checkbox
              >
            </template>
          </el-table-column>
          <el-table-column prop="photos" align="center" label="活动图片">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.photos"
                style="width: 100px; height: 80px; margin-bottom: -10px"
                :src="scope.row.photos"
              ></el-image>
              <p v-else style="width: 100px; height: 80px; line-height: 80px">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            width="200"
            label="活动名称"
            show-overflow-tooltip
          />
          <el-table-column
            prop="description"
            width="250"
            show-overflow-tooltip
            align="center"
            label="活动概述"
          />
          <el-table-column
            prop="startTime"
            width="250"
            show-overflow-tooltip
            align="center"
            label="活动时间周期"
          >
            <template slot-scope="scope">
              {{ scope.row.startTime + " ~ " + scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" align="center" label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 1" type="info">未开始</el-tag>
              <el-tag v-else-if="scope.row.status == 2">进行中</el-tag>
              <el-tag v-else type="danger">已结束</el-tag>
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkedActivity">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="选择地址"
      :visible.sync="showMap"
      width="80%"
      :destroy-on-close="true"
    >
      <map-model
        @lngLatChange="lngLatChange"
        :lng="formData.lng"
        :lat="formData.lat"
      ></map-model>
      <!-- <div id="baidumap" style="width: 100%; height: 400px; margin-top: 10px"></div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMap = false" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  // queryAdvancedFormById,
  activityEventUpdate,
  addActivityEvent,
  activityEventDetails,
  activityEventList,
  getActivityEventLabel,
} from "@/api/companyManage.js";
import { getDataTimeSec, moveToErr } from "@/utils";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请填写活动内容";

var Align = Quill.import("attributors/style/align");
Align.whitelist = ["right", "center", "justify"];
Quill.register(Align, true);

import mapModel from "@/components/common/mapModel";
import commonTable from "@/components/common/commonTable";
import activityForm from "@/components/common/activityForm";
export default {
  name: "appointmentList",
  components: {
    mapModel,
    commonTable,
    quillEditor,
    activityForm,
  },
  data() {
    return {
      editorOption: quillConfig,
      actionURL: this.$store.state.ossUrl,
      dialogVisible: false,
      formData: {
        lng: 0, //经度
        lat: 0, //纬度
        activityFee: 0, //费用
        hostUnit: this.$store.state.loginRoot.userInfo.companyName, //单位名称
        callPhone: this.$store.state.loginRoot.userInfo.phone, //手机号
        activityPlace: "", //详情地址
        signStartTime: "", //报名时间
        signEndTime: "", //报名时间
        placeType: 0,
        ruleIn: [], //参与该活动的才能报名
        ruleOut: [], //没参与该活动的才能报名
        activityType: 0, //0线下活动 1线上活动
        activityLabel: [], //活动标签
        shareFee: "", //退款金额/元

        name: "", //活动名称
        description: "", //活动概述
        photos: "", // 活动封面
        userNum: 1, // 活动人数
        startTime: "", //活动开始时间
        endTime: "", //活动结束时间
        details: "", //活动详情
        isPutOn: true, //是否发布
      },
      checkList: [],
      disabled: true,
      type: "",
      pickerValue: [],
      signTime: "", //报名时间
      form: {
        title: "",
        content: "",
        detailRequests: [
          {
            arfType: "SINGLE",
            title: "单选项",
            required: true,
            childrenDetails: [
              { arfType: "SINGLE", title: "选项1", content: "" },
              { arfType: "SINGLE", title: "选项2", content: "" },
            ],
          },

          // {arfType:'MULTIPLE',title:'多选一',childrenDetails:[{arfType:'MULTIPLE',title:'多选一'},{arfType:'MULTIPLE',title:'多选2'}]},
          // {arfType:'DROP_DOWN',title:'DROP_DOWN',childrenDetails:[{arfType:'DROP_DOWN',title:'DROP_DOWN一'},{arfType:'DROP_DOWN',title:'DROP_DOWN2'}]},
          // {arfType:'TEXTAREA',title:'TEXTAREA',childrenDetails:[{arfType:'TEXTAREA',title:'TEXTAREA1'},{arfType:'TEXTAREA',title:'TEXTAREA2'}]},
          // {arfType:'SCOURE',title:'SCOURE',childrenDetails:[{arfType:'SCOURE',title:'SCOURE1'},{arfType:'SCOURE',title:'SCOURE2'}]},
          // {arfType:'IMAGE',title:'IMAGE',childrenDetails:[{arfType:'IMAGE',title:'IMAGE1'}]},
          // {arfType:'GPS',title:'GPS',childrenDetails:[{arfType:'GPS',title:'GPS1'}]},
          // {arfType:'USERINFO',title:'USERINFO',childrenDetails:[{arfType:'USERINFO',title:'USERINFO1'},{arfType:'USERINFO',title:'USERINFO2'}]},
          // {arfType:'DATEPICKER',title:'DATEPICKER',childrenDetails:[{arfType:'DATEPICKER',title:'DATEPICKER1'}]}
        ],
      },
      formRules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        hostUnit: [
          { required: true, message: "请输入主办单位", trigger: "blur" },
        ],
        callPhone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { message: "手机号格式错误", pattern: this.$store.state.phoneReg },
        ],
        activityPlace: [
          { required: true, message: "请输入活动地点", trigger: "blur" },
        ],
        lng: [{ required: true, message: "请输入经度", trigger: "blur" }],
        lat: [{ required: true, message: "请选择纬度", trigger: "blur" }],
        signEndTime: [{ required: true, message: "请选择报名时间" }],
        endTime: [{ required: true, message: "请选择活动时间" }],
        userNum: [
          { required: true, message: "请输入报名人数", trigger: "blur" },
        ],
        photos: [{ required: true, message: "请上传封面图" }],
        description: [
          { required: true, message: "请输入活动概述", trigger: "blur" },
        ],
        details: [
          { required: true, message: "请输入活动详情", trigger: "blur" },
        ],
        activityLabel: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动标签",
            trigger: "change",
          },
        ],
      },
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      fileList: [],
      //地图设置
      showMap: false,
      txMap: {},
      txMapSearch: {},
      txMapSuggest: {},
      checkedAll: false,
      //活动列表配置
      aeIds: [],
      addType: "ruleIn",
      confirmCheckedRuleInActivity: [], //确认选中的内容,
      confirmCheckedruleOutActivity: [], //确认选中的内容,
      checkedRuleInActivity: {}, //选中的内容,
      checkedruleOutActivity: {}, //选中的内容,
      activityTableData: [],
      showActivity: false, //模态框显示状态
      activityCurrentPage: 1, //当前页
      activityPageSize: 10, //显示条数
      activityLoading: false, //表格加载
      activityTotal: 0, //总条数
      tagData: [],
      parkId: 0,
    };
  },
  created() {
    let checkedPark = sessionStorage.getItem("checkedPark");
    this.parkId = JSON.parse(checkedPark).parkId;
    var startTime = new Date();
    var startDate = new Date(startTime);
    startDate.setDate(startDate.getDate() + 44);
    //检查是否编辑
    let advancedFormId = this.$route.query.aeId;
    this.getActivityEventLabel();
    if (advancedFormId) {
      //-console.log(advancedFormId);
      this.form.aeId = advancedFormId;
      this.type = "edit";
      this.getDetail(advancedFormId);
    } else {
      this.signTime = [
        getDataTimeSec(new Date()),
        getDataTimeSec(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
      ];
      this.pickerValue = [
        getDataTimeSec(new Date().getTime() + 1000 * 60 * 60 * 24 * 14),
        getDataTimeSec(startDate),
      ];
      this.formData.signStartTime = this.signTime[0];
      this.formData.signEndTime = this.signTime[1];

      this.formData.startTime = this.pickerValue[0];
      this.formData.endTime = this.pickerValue[1];
    }
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  watch: {
    "formData.details": function (val) {
      //-console.log(val);
    },
    "formData.lng": function (val) {
      this.formData.lng = Number(val);
    },
    "formData.lat": function (val) {
      this.formData.lat = Number(val);
    },
  },
  mounted() {},
  methods: {
    /**@method 获取活动标签 */
    getActivityEventLabel() {
      getActivityEventLabel({ type: 1 }).then((res) => {
        if (res.code === 200) {
          this.tagData = res.data;
        }
      });
    },
    /**@method 选中活动 */
    selectActivity(isOpen, data) {
      if (isOpen) {
        if (this.addType === "ruleIn") {
          this.checkedRuleInActivity[data.aeId] = data;
        } else if (this.addType === "ruleOut") {
          this.checkedruleOutActivity[data.aeId] = data;
        }
      } else {
        if (this.addType === "ruleIn") {
          delete this.checkedRuleInActivity[data.aeId];
        } else if (this.addType === "ruleOut") {
          delete this.checkedruleOutActivity[data.aeId];
        }
      }
    },
    delcheckedActivity(delType, type, index) {
      if (type === "all") {
        if (delType === "ruleIn") {
          this.confirmCheckedRuleInActivity = [];
        } else if (delType === "ruleOut") {
          this.confirmCheckedruleOutActivity = [];
        }
      } else {
        if (delType === "ruleIn") {
          this.confirmCheckedRuleInActivity.splice(index, 1);
        } else if (delType === "ruleOut") {
          this.confirmCheckedruleOutActivity.splice(index, 1);
        }
      }
    },
    /**@method 确认选中活动 */
    checkedActivity() {
      this.showActivity = false;
      let confirmCheckedRuleInActivity = [];
      let confirmCheckedruleOutActivity = [];
      if (this.addType === "ruleIn") {
        for (let key in this.checkedRuleInActivity) {
          confirmCheckedRuleInActivity.push(this.checkedRuleInActivity[key]);
        }
        //确认选中活动进行保存
        this.confirmCheckedRuleInActivity = confirmCheckedRuleInActivity;
      } else if (this.addType === "ruleOut") {
        for (let key in this.checkedruleOutActivity) {
          confirmCheckedruleOutActivity.push(this.checkedruleOutActivity[key]);
        }
        this.confirmCheckedruleOutActivity = confirmCheckedruleOutActivity;
      }
    },
    /**@method 显示活动列表 */
    showActivityFun(type) {
      this.addType = type;
      this.showActivity = true;
      this.aeIds = [];
      this.activityTableData = [];
      this.checkedRuleInActivity = {};
      this.checkedruleOutActivity = {};
      this.getActivityList();
    },
    /**@method 活动获取列表 */
    async getActivityList() {
      let params = {
        pageSize: this.activityPageSize,
        pageNum: this.activityCurrentPage,
        parkId: this.parkId,
      };
      try {
        this.activityLoading = true;
        let res = await activityEventList(params);
        this.activityLoading = false;

        const { data } = res;
        this.activityTableData = data.pageInfo.list;
        this.activityTotal = data.pageInfo.total;
      } catch (error) {
        this.activityLoading = false;
        this.activityTableData = [];
      }
    },
    /**@method 活动切换行 */
    activityHandleSizeChange(val) {
      this.activityPageSize = val;
      this.getActivityList();
    },
    /**@method 活动切换下一页 */
    activityHandleCurrentChange(val) {
      this.activityCurrentPage = val;
      this.getActivityList();
    },
    /**活动配置 */
    /**@method 监听报名时间 */
    changeApplyTime(date) {
      if (date && date.length == 2) {
        this.formData.signStartTime = date[0];
        this.formData.signEndTime = date[1];
      } else {
        this.formData.signStartTime = "";
        this.formData.signEndTime = "";
        this.pickerValue = [];
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    /**@method 监听活动时间 */
    changePickerValue(date) {
      if (date && date.length == 2) {
        this.formData.startTime = date[0];
        this.formData.endTime = date[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    /**@method 显示地图 */
    showMapFun() {
      this.showMap = true;
    },
    lngLatChange(lnglat) {
      //-console.log(lnglat);
      this.formData.lng = lnglat.lng;
      this.formData.lat = lnglat.lat;
    },
    //移除封面
    handleLogoRemove(file, fileList) {
      this.fileList = [];
      this.formData.photos = "";
    },
    //封面上传成功
    handleSuccess(response, file, fileList) {
      this.$message.closeAll(this.fileList);
      this.$message({
        message: "上传成功",
        type: "success",
      });
      //-console.log('file',file)
      this.formData.photos = this.ossUrl + file.response.data;

      this.fileList = fileList;
    },
    //请求高级表单详情
    getDetail(id) {
      let data = {
        aeId: id,
      };
      activityEventDetails(data)
        .then((res) => {
          this.form.detailRequests = res.data.form.detailRequests
            ? res.data.form.detailRequests
            : [];
          let data = res.data.activityEvent;
          this.formData = {
            lng: data.lng, //经度
            lat: data.lat, //纬度
            activityFee: data.activityFee ? data.activityFee : 0, //费用
            hostUnit: data.hostUnit, //单位名称
            callPhone: data.callPhone, //手机号
            activityPlace: data.activityPlace, //详情地址
            signStartTime: data.signStartTime, //报名时间
            signEndTime: data.signEndTime, //报名时间
            placeType: 0,
            ruleIn: data.ruleIn ? data.ruleIn.split(",").map(Number) : [], //参与该活动的才能报名
            ruleOut: data.ruleOut ? data.ruleOut.split(",").map(Number) : [], //没参与该活动的才能报名
            activityType: data.activityType, //线上活动 线下活动
            activityLabel: data.activityLabel
              ? data.activityLabel.split(",").map(Number)
              : [], //活动标签
            shareFee: data.shareFee ? data.shareFee : 0, //退款金额/元

            // lng: 0,//经度
            // lat: 0,//纬度
            // activityFee: 0,//费用
            // hostUnit: "",//单位名称
            // callPhone: "",//手机号
            // activityPlace: "",//详情地址
            // signStartTime: "",//报名时间
            // signEndTime: "",//报名时间

            name: data.name, //活动名称
            description: data.description, //活动概述
            photos: data.photos, // 活动封面
            userNum: data.userNum ? data.userNum : 0, // 活动人数
            startTime: data.startTime, //活动开始时间
            endTime: data.endTime, //活动结束时间
            details: data.details, //活动详情
            isPutOn: data.isPutOn,
          };
          this.confirmCheckedRuleInActivity = data.ruleInList
            ? data.ruleInList
            : []; //确认选中的内容,
          this.confirmCheckedruleOutActivity = data.ruleOutList
            ? data.ruleOutList
            : []; //确认选中的内容,
          this.pickerValue = [data.startTime, data.endTime]; //活动开始时间
          this.signTime = [data.signStartTime, data.signEndTime]; //报名开始时间

          if (data.photos) {
            this.fileList = [{ url: data.photos, uid: 1 }];
          }
          //-console.log(this.form)
        })
        .catch((err) => {});
    },
    //保存表单
    saveForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          let getTimeFun = function (time) {
            return new Date(time).getTime();
          };
          if (
            getTimeFun(this.formData.signStartTime) >
            getTimeFun(this.formData.startTime)
          ) {
            this.$message({
              type: "warning",
              message: "报名开始时间不能大于活动开始时间",
            });
            return;
          }
          if (
            getTimeFun(this.formData.signEndTime) >
            getTimeFun(this.formData.endTime)
          ) {
            this.$message({
              type: "warning",
              message: "报名结束时间不能大于活动结束时间",
            });
            return;
          }
          // if (!this.userNum || !this.name || !this.description || !this.details || !this.photos) {
          //   return this.$message.error('请先完善活动内容！')
          // }
          this.form.detailRequests = this.$refs.activityForm.detailRequests;
          this.form.detailRequests.forEach((item, index) => {
            item.serialNumber = index + 1;
            if (item.childrenDetails) {
              item.childrenDetails.forEach((child, ind) => {
                child.serialNumber = ind + 1;
              });
            }
            if (item.arfType == "IMAGE") {
              item.childrenDetails.forEach((child, ind) => {
                child.serialNumber = ind + 1;
                //图片单独处理
                child.imgNum = child.title;
              });
            }
          });
          //-console.log(this.form)
          let data = {
            ...this.formData,
            forms: this.form.detailRequests,
            parkId: this.parkId,
          };
          let ruleIn = [];
          let ruleOut = [];
          if (this.confirmCheckedRuleInActivity.length > 0) {
            for (let row of this.confirmCheckedRuleInActivity) {
              ruleIn.push(row.aeId);
            }
          }
          if (this.confirmCheckedruleOutActivity.length > 0) {
            for (let row of this.confirmCheckedruleOutActivity) {
              ruleOut.push(row.aeId);
            }
          }
          if (this.form.aeId && ruleIn.indexOf(Number(this.form.aeId)) != -1) {
            this.$message({
              type: "warning",
              message: "参与该活动的才能报名的活动不能选为当前活动",
            });
            return;
          }
          if (this.form.aeId && ruleOut.indexOf(Number(this.form.aeId)) != -1) {
            this.$message({
              type: "warning",
              message: "没参与该活动的才能报名的活动不能选为当前活动",
            });
            return;
          }
          data.ruleIn = ruleIn;
          data.ruleOut = ruleOut;
          //活动类型为线上 清空地址坐标共享空间
          if (data.activityType === 1) {
            delete data.activityPlace;
            delete data.lng;
            delete data.lat;
          }
          if (this.type == "edit") {
            this.form.isDraft = false;
            //编辑
            data.aeId = this.form.aeId;
            activityEventUpdate(data)
              .then((res) => {
                if (res.code === 200) {
                  if (
                    typeof res.data === "string" &&
                    res.data.indexOf("表单修改未完成") != -1
                  ) {
                    this.$message({
                      type: "success",
                      message: "发布成功，活动报名已开始，无法编辑表单",
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: "发布成功",
                    });
                  }
                  //去列表
                  this.$router.go(-1);
                }
              })
              .catch((err) => {
                if (err.code === 201) {
                  this.$message.error(err.message);
                }
              });
          } else {
            //新增
            addActivityEvent(data)
              .then((res) => {
                if (res.code === 200) {
                  if (
                    typeof res.data === "string" &&
                    res.data.indexOf("表单修改未完成") != -1
                  ) {
                    this.$message({
                      type: "success",
                      message: "发布成功，活动报名已开始，无法编辑表单",
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: "发布成功",
                    });
                  }
                  //去列表
                  this.$router.go(-1);
                }
              })
              .catch((err) => {
                if (err.code === 201) {
                  this.$message.error(err.message);
                }
              });
          }
        } else {
          moveToErr(this);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //转化时间
  },
};
</script>
<style>
.shareSpace .el-form-item__content {
  width: calc(100% - 110px);
}
</style>
<style lang="scss" scoped>
.hover_pointer {
  transition: all 0.3s linear;
}

.hover_pointer:hover {
  transform: scale(1.2);
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/.el-tabs__content {
  background: #ffffff;
}

.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}

.commentForm {
  border: 1px solid #dcdfe6;
  padding-top: 10px;
  margin-top: 10px;
}
</style>